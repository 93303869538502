import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  submitFilters(e) {

    const moduleType = e.currentTarget.dataset.moduleType;
    const filterType = e.currentTarget.dataset.filterType;

    // Initialize filters
    let filters = this.constructFilters(filterType, e);

    // Construct the query string for the filters
    const queryString = this.buildQueryString(filters);

    const taskTab = document.querySelector('#tab-1');
    taskTab.setAttribute('data-is-active', 'true');
    taskTab.setAttribute('aria-selected', 'true');

    const resourceTab = document.querySelector('#tab-0');
    resourceTab.setAttribute('data-is-active', 'false');
    resourceTab.setAttribute('aria-selected', 'false');

    // Construct the final URL with the query string
    const srcValue = `/tasks?module_type=${encodeURIComponent(moduleType)}&${queryString}`;
    document.getElementById('resources-index--container--page-bottom-view-frame').setAttribute('src', srcValue);

    // Scroll to the bottom of the page and show toast when the frame is rendered
    document.addEventListener("turbo:frame-render", (ev) => {
      const decodedSrc = this.decodeUrlWithPlus(ev.target.src);
      const decodedSrcValue = this.decodeUrlWithPlus(srcValue);
      if (ev.target.id == "resources-index--container--page-bottom-view-frame" && decodedSrc.includes(decodedSrcValue)) {
        document.getElementById('index-tasks-card-container--list-container').scrollIntoView({ behavior: 'smooth', block: 'end' });
        const label = this.element.querySelector(".summary-component--detail-component--content--label").innerText;
        super.renderToast("body", `Filtering by ${label}`, null, false, null, 6000)
      }
    })
  }

  // New method to decode URL and handle '+' signs
  decodeUrlWithPlus(url) {
    return decodeURIComponent(url.replace(/\+/g, ' '));
  }
  
  // Dynamically construct filters based on filterType
  constructFilters(filterType, e) {
    const todayStr = new Date().toISOString().split('T')[0];
    const weekRange = this.getWeekDateRange();
    const assigneeName = e.currentTarget.dataset.assigneeName;

    const filterMappings = {
      'to_do': { 
        'list_items_task_status': ['to_do'],
        'list_items_assignee_name':[assigneeName]
      },
      'completed_tasks': {
        'list_items_task_status': ['completed'],
        'list_items_assignee_name':[assigneeName],
        'list_items_completed_at': {
          'value': weekRange,
          'filter_operator': 'In Between'
        }
      },
      'tasks_assigned_today': {
        'list_items_last_assignment_date': {
          'value': todayStr,
          'filter_operator': 'On'
        },
        'list_items_assignee_name':[assigneeName]
      },
      'overdue': { 'list_items_task_status': ['over_due'] , 'list_items_assignee_name':[e.currentTarget.dataset.assigneeName] },
    };

    // Return appropriate filter or default if none matches
    return filterMappings[filterType] || {};
  }

  // Get formatted date range string for the current week
  getWeekDateRange() {
    const beginningOfWeek = new Date();
    beginningOfWeek.setDate(beginningOfWeek.getDate() - beginningOfWeek.getDay() + 1); // Monday
    const endOfWeek = new Date(beginningOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6); // Sunday

    const beginningOfWeekStr = beginningOfWeek.toISOString().split('T')[0];
    const endOfWeekStr = endOfWeek.toISOString().split('T')[0];
    return `${beginningOfWeekStr}..${endOfWeekStr}`;
  }

  // Build query string from filters object
  buildQueryString(filters) {
    return Object.entries(filters)
      .map(([key, value]) => {
        if (typeof value === 'object' && !Array.isArray(value)) {
          return Object.entries(value)
            .map(([innerKey, innerValue]) =>
              `filters[${encodeURIComponent(key)}][${encodeURIComponent(innerKey)}]=${encodeURIComponent(innerValue)}`
            )
            .join('&');
        } else if (Array.isArray(value)) {
          return value.map(v => `filters[${encodeURIComponent(key)}][]=${encodeURIComponent(v)}`).join('&');
        }
        return `filters[${encodeURIComponent(key)}]=${encodeURIComponent(value)}`;
      })
      .join('&');
  }
}
