import ApplicationController from "./application_controller";
import { abbrEl } from "../utils/abbr_el";
import { addErrors } from "../utils/form_validator";
export default class extends ApplicationController {
  static targets = ["assignToField", "fromField"]
  static values = {
    randomNum: String,
    userType: String,
    contingency: Boolean
  }

  toggleEl(el, toggle) {
    if (toggle && !this.contingencyValue) {
      el.removeAttribute("disabled");
    } else {
      setTimeout(() => {
        el.setAttribute("disabled", true);
      }, 150);
    }
  }

  toggleDaysField(e) {
    const dateField = document.querySelector("#list-item-form-date-field");
    dateField.classList.remove('has-errors'); // Remove error class if it exists
    const sibling = dateField.previousElementSibling;

    const hoursField = document.querySelector("#list-item-form-hours-field");
    const minutesField = document.querySelector("#list-item-form-minutes-field");

    if (e.target.value == "on_date") {
      dateField.value = "";

      dateField.setAttribute("disabled", true);

      if(hoursField) {
        hoursField.value = "";
        hoursField.setAttribute("disabled", true);
      }

      if(minutesField) {
        minutesField.value = "";
        minutesField.setAttribute("disabled", true);
      }

      dateField.removeAttribute("required");
      const abbr = sibling.querySelector("abbr");
      if (abbr) abbr.remove();
    } else {
      dateField.removeAttribute("disabled");

      if(hoursField) hoursField.removeAttribute("disabled");
      if(minutesField) minutesField.removeAttribute("disabled");

      dateField.setAttribute("required", true);
      if (!sibling.querySelector("abbr")) sibling.prepend(abbrEl());
    }
  }

  toggleDefaultDaysField(e) {
    const randNum = e.target.getAttribute("data-rand-num");
    const dateField = document.querySelector(`#list_item_due_days${randNum}`);
    const currentInputSelection = e.target;
    const otherFields = document.querySelectorAll(`.table-radio-input-${currentInputSelection.dataset.randNum}`); 
    // Make default checkboxes act like radio buttons
    otherFields.forEach(input => {
      if (input !== currentInputSelection) {
        input.checked = false;
        input.removeAttribute("checked");
      } 
      if (input == currentInputSelection) {
        input.checked = true;
      }
    })
    if (e.target.value == "on_date") {
      dateField.value = "";
      dateField.setAttribute("disabled", true);
      dateField.removeAttribute("required");
    } else {
      dateField.removeAttribute("disabled");
      dateField.setAttribute("required", true);
    }
  }

  resetDueDateField(e) {
    const dueDateField = document.querySelector("#to_do_task_due_date_field");
    const sibling = dueDateField.parentElement;
    sibling.querySelector("abbr")?.remove();
    dueDateField.removeAttribute("required");
    dueDateField.value = "";
    const dateTypeField = document.querySelector("#task_form_date_type_field");
    dateTypeField.classList.remove("is-disabled");
  }

  handleChangeDueDate(e) {
    const dateTypeField = document.querySelector("#task_form_date_type_field");
    const dueDateField = document.querySelector("#to_do_task_due_date_field");
    const dueDateFieldContainer = document.querySelector("#to_do_task_due_date_field_container");
    const sibling = dueDateField.parentElement;
    const dateFormFow = document.querySelector("#date-form-row");
    const dateField = document.querySelector("#list-item-form-date-field");
    const taskType = document.querySelector('#list_items_task_type');
    const dueScheduleTimeFieldRow = document.querySelector('#due-schedule-time-template-task-field');
    const dueScheduleTime = document.querySelector('#list_items_due_time');

    if (dateTypeField.value === "specific_date") {
      if (!sibling.querySelector("abbr")) sibling.prepend(abbrEl());
      dueDateField.setAttribute("required", true);
      dueDateField.removeAttribute("disabled");
      dueDateField.classList.add("form-text-input-required");
      dueDateFieldContainer.classList.remove("vanish");
      dateFormFow.classList.add("vanish");

      if (dueScheduleTime) {
        dueScheduleTime.removeAttribute("disabled", true);
        dueScheduleTimeFieldRow.classList.remove("vanish");
      }

      if (!dateField) return; // If there isn't the additional date fields, break out
      const dateFieldSibling = dateField.previousElementSibling;
      dateField.value = "";
      dateField.removeAttribute("required");

      const abbr = dateFieldSibling.querySelector("abbr");
      if (abbr) abbr.remove();

    } else {
      dueDateField.removeAttribute("required");
      dueDateField.classList.remove("form-text-input-required");
      const abbr = sibling.querySelector("abbr");
      if (abbr) abbr.remove();

      dateField.setAttribute("required", true);
      dateField.removeAttribute("disabled");

      dueDateFieldContainer.classList.add("vanish");
      dueDateField.setAttribute("disabled", true);

      dateFormFow.classList.remove("vanish");

      if ((taskType == undefined || (taskType && taskType.value == 'user_task')) && dueScheduleTimeFieldRow) {
        dueScheduleTimeFieldRow.classList.add("vanish");
        dueScheduleTime.setAttribute("disabled", true);
      }

      this.updateFormDayTypeLabel();
    }

    if (taskType) { taskType.setAttribute('data-selected-date_type', dateTypeField.value); }
  }

  changeTaskType(e) {
    const taskType = e.currentTarget.value;
    const currentAssignToValue = this.assignToFieldTarget.dataset.currentValue;
    const hoursField = document.querySelector("#list-item-form-hours-field");
    const minutesField = document.querySelector("#list-item-form-minutes-field");
    const hoursFieldLabelForm = document.querySelector('#label-form-due-hours');
    const minutesFieldLabelForm = document.querySelector('#label-form-due-minutes');

    if (this.assignToFieldTarget.value !== "") this.assignToFieldTarget.dataset.currentValue = this.assignToFieldTarget.value;

    if (taskType === "user_task") {
      this.assignToFieldTarget.value = currentAssignToValue

      hoursFieldLabelForm.classList.remove("vanish");
      minutesFieldLabelForm.classList.remove("vanish");

      hoursField.removeAttribute("disabled");
      minutesField.removeAttribute("disabled");
    } else {
      this.assignToFieldTarget.value = ""
      hoursField.value = "";
      minutesField.value = "";

      hoursFieldLabelForm.classList.add("vanish");
      minutesFieldLabelForm.classList.add("vanish");

      hoursField.setAttribute("disabled", true);
      minutesField.setAttribute("disabled", true);
    }

    this.updateFormDayTypeLabel();
  }

  updateFormDayTypeLabel() {
    let oldValue = document.querySelector("#list-item-form-date-field").dataset.oldLabelValue;
    let daysValue = '';

    if (['email', 'sms'].includes(document.querySelector("#list_items_task_type")?.value)) {
      daysValue = "Business Days"
    } else {
      daysValue = oldValue
    }

    document.querySelector("#label-form-day-type").firstElementChild.innerText = daysValue;
  }

  insertCommaSeparated(e) {
    if (e.key === ' ') {
      const input = e.target;
      let inputValue = input.value.trim();

      // Extract the last segment of the input to check if it's an email
      const lastSegment = inputValue.split(/,\s*/).pop();
      if (this.validateEmail(lastSegment)) {
          // Add a comma and a space after the email
          input.value = inputValue + ', ';
      } else {
        addErrors(input);
        super.renderToast("task_from_input", "Please enter a valid email addresses.", "danger", false, null, 5000)
      }
    }
  }

  validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  validateLibraryTask(e) {
    const customType = document.querySelector('#custom_task_false');
    const libraryTaskValue = document.querySelector('#library_task_id').value.trim();

    if(customType.checked && libraryTaskValue === ''){
      e.preventDefault();
      return super.renderToast(
        "body", "Task name needs to be used from the existing Library Tasks", "danger", false, null, 4500, true
      );
    }
  }
}
